export type StrapiLiquidStakingOptions = {
  id: number;
  attributes: LinkOption;
};

export type LinkOption = {
  key: string;
  link: string;
  title: string;
};

export type StrapiSubHeaderOption = {
  title: string;
  key: string;
  is_component: boolean;
  is_External: boolean;
  link: string;
  icon_key: string;
};

export type StrapiCommunityDetails = {
  section_details: StrapiSectionDetail;
  communities_data: StrapiCard[];
};

export type StrapiSectionDetail = {
  title?: string;
  sub_title?: string;
  cta_text?: string;
  cta_link?: string;
  section_id?: string;
};

export type StrapiCard = {
  key?: string;
  link?: string;
  title?: string;
  description?: string;
  cta_text?: string;
  img?: any;
  img_key?: string;
};

export type StrapiAuditsData = {
  key: string;
  title: string;
  link: string;
};

export type StrapiRightSection = {
  title: string;
  subTitle?: string;
  key: string;
  data: {
    data: Array<any>;
  };
};

export type StrapiCenterHeaderData = {
  key: string;
  value?: string;
  title: string;
  tooltip_text?: string;
  tooltip_cta_text?: string;
  tooltip_cta_link?: string;
  ledger_tooltip_text?: string;
  ledger_tooltip_cta_text?: string;
  ledger_tooltip_cta_link?: string;
  ledger_apy_add_on_perc?: number;
  boosted_apy?: string;
};

export type StrapiDefiPageData = {
  key: string;
  subTitle: string;
  title: string;
  defiItems: DefiItemGroup[];
  banner: StrapiDefiBanner;
  tooltipText: string;
};

export type StrapiDefiBanner = {
  title: string;
  cta_text?: string;
  cta_link?: string;
  img_key?: string;
};

export type DefiItemGroup = {
  title: string;
  hasApy: boolean;
  isSDPools: boolean;
  items: DefiItem[];
};

export type DefiItem = {
  apiKey: string;
  showToken: boolean;
  token: string;
  type: string;
  platform: any;
  pairPlatform: any;
  links: any;
  tokenIconKey: string;
  apyMessage?: string;
};

export enum DefiItemActionButtonType {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  BADGE = "badge",
}

export type DefiItemActionData = {
  actionCta: string;
  actionLink: string;
  buttonType: DefiItemActionButtonType;
};

export type DefiData = {
  [key: string]: {
    apyTooltip?: {
      show: boolean;
      text: string;
    };
    tvlTooltip?: {
      show: boolean;
      text: string;
    };
    tvl: string;
    apy: string;
    showTVL: boolean;
    enable: boolean;
    paused: boolean;
    tags?: string[];
  };
};

export type ActionItems = {
  title: string;
  link: string;
  type: string;
};

export type BannerStatistics = {
  title: string;
  key: string;
  value?: string;
};

export type LandingPageData = {
  key: string;
  section_details: StrapiSectionDetail;
  background_img_key: string;
  navItems: LandingNavItem[];
  sections?: Array<any>;
  communities: StrapiCard[];
  statistics?: Array<BannerStatistics>;
  banner_cta_section?: Array<ActionItems>;
  extra_content: {
    section_details: StrapiSectionDetail;
    subscribe_form: SubscribeForm;
  };
};

export type SubscribeForm = {
  placeholder?: string;
  submit_text?: string;
};

export type LandingNavItem = {
  section_id: string;
  section_title: string;
};

export type GasFeesItem = {
  stake: number;
  unstake: number;
  usdStake: number;
  usdUnstake: number;
};
