import { useCallback, Fragment } from "react";
import {
  Box,
  Center,
  Flex,
  Text,
  Button,
  Divider,
  Link,
  Show,
} from "@chakra-ui/react";

import { TextPrimary, TextSecondary } from "theme";
import Icon from "@components/Icon";

import { getBannerImage } from "@utils/common";
import { formatNumber, getCommunityIcon } from "@utils/globalCommon";
import { trackEvent, useDappSDKProvider } from "dapp-sdk-v2";

import {
  ANALYTICS_DASBOARD_CLICK,
  MARKETING_PRIMARY_CLICK,
  MARKETING_SECONDARY_CLICK,
  SOCIALS_CLICK,
} from "@constants/analytics";

import {
  ActionItems,
  BannerStatistics,
  DefiItemActionButtonType,
  StrapiCard,
  StrapiSectionDetail,
} from "@customtypes/global";

import LinkComponent from "@components/Link";
import { REWARDSCALCULATORMAP } from "@constants/constants";

import BaseImage from "dapp-sdk-v2/components/Image";

import usePageData from "@hooks/usePageData";

interface HeroProps {
  sectionDetails: StrapiSectionDetail;
  communities: StrapiCard[];
  actionItems?: ActionItems[];
  statistics?: BannerStatistics[];
}

const TOKEN = process.env.NEXT_PUBLIC_TOKEN || "";

const Hero = ({
  sectionDetails,
  communities,
  actionItems,
  statistics,
}: HeroProps) => {
  const { tvlData, apyData } = useDappSDKProvider();

  const pageData = usePageData("$.page.data")[0]?.pageData || {};
  const { analytics_cta, analytics_link, analytics_text } = pageData;

  const getButtonProps = useCallback(
    (buttonVariant: DefiItemActionButtonType) => {
      if (buttonVariant === DefiItemActionButtonType.PRIMARY) {
        return {
          bg: "primary",
          color: "white",
        };
      } else if (buttonVariant === DefiItemActionButtonType.SECONDARY) {
        return {
          variant: "outline",
          color: "textPrimary",
          bg: "white",
          borderColor: "primary",
        };
      }
    },
    []
  );

  const getEventName = (buttonVariant: DefiItemActionButtonType) => {
    if (buttonVariant === DefiItemActionButtonType.PRIMARY) {
      return MARKETING_PRIMARY_CLICK;
    } else if (buttonVariant === DefiItemActionButtonType.SECONDARY) {
      return MARKETING_SECONDARY_CLICK;
    }
    return "";
  };

  const getStatisticsSection = useCallback(
    (key: string, value: string) => {
      const getValue = () => {
        if (value) {
          return value;
        } else {
          switch (key) {
            case "apy":
              return apyData?.apy && apyData?.apy && apyData.apy > 0
                ? `${Number(Number(apyData.apy).toFixed(2))}%`
                : "-";
            case "totalStaked":
              return tvlData?.tvl ? (
                <>
                  {formatNumber(tvlData.tvl.value)}{" "}
                  <Text color="textSecondary" as="span" fontSize={16}>
                    {REWARDSCALCULATORMAP[TOKEN]}
                  </Text>
                </>
              ) : (
                "-"
              );
          }
        }
      };
      return (
        <TextPrimary
          fontWeight="600"
          fontSize={{ base: "16px", lg: "32px" }}
          lineHeight={{ base: "24px", lg: "38px" }}
        >
          {getValue()}
          {key === "apr" && (
            <TextPrimary
              fontSize={{ base: "12px", lg: "16px" }}
              lineHeight={{ base: "26px", lg: "24px" }}
              display="inline"
            >
              APR
            </TextPrimary>
          )}
        </TextPrimary>
      );
    },
    [apyData?.apy, tvlData?.tvl]
  );

  return (
    <Box
      // mb={{ base: "40px", lg: "64px" }}
      w="full"
      maxW="1192px"
      mt={{ base: "58px", lg: "76px" }}
    >
      <Center
        h={{ base: "fit-content", lg: "688px" }}
        w="full"
        justifyContent="space-between"
        flexDir={{ base: "column-reverse", lg: "row" }}
      >
        <Flex
          flexDir="column"
          textAlign={{ base: "center", lg: "left" }}
          maxW="540px"
          alignItems={{ base: "center", lg: "flex-start" }}
          mt={{ base: "48px", lg: 0 }}
          px={2}
        >
          <Text
            fontWeight="700"
            fontSize={{ base: "32px", lg: "64px" }}
            lineHeight="120%"
            maxW={{ base: "280px", lg: "100%" }}
          >
            {sectionDetails?.title}
          </Text>
          <Text
            fontWeight="500"
            fontSize={{ base: "12px", lg: "16px" }}
            color="textSecondary"
            mt={{ base: "12px", lg: "24px" }}
          >
            {sectionDetails?.sub_title}
          </Text>

          {actionItems && (
            <Flex mt={{ base: "1.5rem", lg: "2rem" }} gap="1rem">
              {actionItems.map((cta: any) => (
                <LinkComponent
                  key={cta.title}
                  link={cta.link}
                  onClick={() =>
                    trackEvent(getEventName(cta.type), {
                      cta: cta.title,
                      link: cta.link,
                    })
                  }
                >
                  <Button
                    h="56px"
                    p={{ base: "16px 32px", lg: "16px 64px" }}
                    minW={{ base: "156px", lg: "inherit" }}
                    {...getButtonProps(cta.type)}
                  >
                    {cta.title}
                  </Button>
                </LinkComponent>
              ))}
            </Flex>
          )}

          {statistics && (
            <Flex
              justifyContent={{ base: "center", lg: "center" }}
              gap={{ base: "8px", lg: "32px" }}
              mt="50px"
              alignItems="center"
              width={{ base: "100%", lg: "inherit" }}
              maxW={{ base: "328px", lg: "inherit" }}
            >
              {statistics.map((statistic: any, index: number) => {
                return (
                  <Fragment key={statistic.title}>
                    <Flex
                      flexDir="column"
                      gap="4px"
                      minW={{ base: "80px", lg: "100px" }}
                      maxW="225px"
                    >
                      {getStatisticsSection(statistic.key, statistic.value)}
                      <TextSecondary
                        fontWeight={{ base: "700", lg: "500" }}
                        fontSize={{ base: "10px", lg: "16px" }}
                        lineHeight={{ base: "16px", lg: "24px" }}
                      >
                        {statistic.title}
                      </TextSecondary>
                    </Flex>
                    {index < statistics.length - 1 && (
                      <Divider
                        orientation="vertical"
                        borderColor="seperator"
                        borderWidth="1px"
                        height={{ base: "32px", lg: "48px" }}
                      />
                    )}
                  </Fragment>
                );
              })}
            </Flex>
          )}

          {analytics_cta && analytics_link ? (
            <Flex gap="6px" mt="1.5rem" alignItems="center">
              {analytics_text && (
                <TextSecondary
                  fontWeight={{ base: "700", lg: "400" }}
                  fontSize={{ base: "10px", lg: "16px" }}
                  lineHeight={{ base: "16px", lg: "24px" }}
                >
                  {analytics_text}
                </TextSecondary>
              )}

              <a
                href={analytics_link}
                target="_blank"
                rel="noreferrer"
                onClick={() => trackEvent(ANALYTICS_DASBOARD_CLICK)}
              >
                <Text
                  color="primary"
                  fontWeight="600"
                  fontSize={{ base: "10px", lg: "14px" }}
                  lineHeight={{ base: "12px", lg: "20px" }}
                >
                  {analytics_cta}
                  <Divider
                    variant="dashed"
                    border="1px"
                    borderColor="primary"
                  />
                </Text>
              </a>
            </Flex>
          ) : null}

          <Flex
            mt="24px"
            gap="8px"
            alignSelf={{ base: "center", lg: "flex-start" }}
          >
            {communities.map((data: any) => (
              <Link
                key={data.key}
                isExternal
                href={data.link}
                onClick={() =>
                  trackEvent(SOCIALS_CLICK, { community_type: data.key })
                }
              >
                <Center
                  bg="#E6F8EF"
                  h="40px"
                  w="40px"
                  borderRadius="50%"
                  _hover={{ border: "1px solid", borderColor: "primary" }}
                  cursor="pointer"
                >
                  <Icon
                    height="24px"
                    width="24px"
                    Icon={getCommunityIcon(data.key)}
                  />
                </Center>
              </Link>
            ))}
          </Flex>
        </Flex>
        <Flex
          px={2}
          h={{ base: 324, lg: 409 }}
          overflow="hidden"
          alignItems="center"
        >
          <Show above="lg">
            <BaseImage
              src={getBannerImage(TOKEN)}
              alt="security"
              width={536}
              height={409}
            />
          </Show>
          <Show below="lg">
            <BaseImage
              src={getBannerImage(TOKEN)}
              alt="security"
              width={328}
              height={250}
            />
          </Show>
        </Flex>
      </Center>
    </Box>
  );
};

export default Hero;
