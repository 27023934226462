import { useEffect, useMemo, useState } from "react";
import { Center, Divider, Flex, Text, LightMode } from "@chakra-ui/react";
import dynamic from "next/dynamic";

import How from "@components/landingPage-v2/How";
import Meet from "@components/landingPage-v2/Meet";

import Header from "@components/landingPage-v2/Header";
import NodeOperatorsBanner from "@components/landingPage-v2/NodeOperatorsBanner";

import Hero from "@components/landingPage-v2/Hero";
import PageHead from "@components/PageHead/PageHead";

const Audits = dynamic(() => import("@components/landingPage-v2/Audits"));
const Newsletter = dynamic(
  () => import("@components/landingPage-v2/Newsletter")
);
const RewardCalculator = dynamic(
  () => import("@components/landingPage-v2/RewardCalculator"),
  {
    ssr: false,
  }
);
const Blogs = dynamic(() => import("@components/landingPage-v2/Blogs"));
const Partners = dynamic(() => import("@components/landingPage-v2/Partners"));
const FAQs = dynamic(() => import("@components/landingPage-v2/FAQ"));
const FAQsContent = dynamic(
  () => import("@components/landingPage-v2/FAQContent")
);
const KnowMore = dynamic(() => import("@components/landingPage-v2/KnowMore"));
const Footer = dynamic(() => import("@components/landingPage-v2/Footer"));

import { LandingPageData } from "@customtypes/global";
import { STRAPI_SECTION_IDS } from "@constants/landing";

import { PageProvider } from "@providers/PageProvider";

import usePageData from "@hooks/usePageData";

import { prepareNavData } from "@utils/common";
import { fetchPageData } from "@utils/globalCommon";
import { trackEvent } from "dapp-sdk-v2";

import { PRIMARY_TABS } from "@constants/analytics";

let timerId = -1;

const Home = () => {
  const { page } = usePageData();

  const { navData } = useMemo(
    () => prepareNavData(page.data.navData),
    [page.data.navData]
  );

  const pageData: LandingPageData = page.data.pageData;

  const { sections, section_details, communities } = pageData;

  const navItems = useMemo(() => pageData.navItems, [pageData]);
  const [activeMenu, setActiveMenu] = useState(navItems[0].section_id);
  const [isNavigating, setNavigating] = useState(false);
  const [isSubHeaderSticky, setSubHeaderSticky] = useState(false);

  useEffect(() => {
    // TODO: optimize code
    const updateScrollDirection = () => {
      const section = document.getElementById("sub-header");
      if (section && section?.getBoundingClientRect().top <= 76) {
        setSubHeaderSticky(true);
      } else {
        setSubHeaderSticky(false);
      }
    };
    window.addEventListener("scroll", updateScrollDirection);
    return () => {
      window.removeEventListener("scroll", updateScrollDirection);
    };
  }, []);

  useEffect(() => {
    if (isNavigating) {
      clearTimeout(timerId);
      const element = document.querySelector<HTMLElement>(`#${activeMenu}`);
      element?.scrollIntoView({
        behavior: "smooth",
        inline: "center",
      });

      timerId = window.setTimeout(() => setNavigating(false), 3000);
    }
  }, [activeMenu, isNavigating]);

  useEffect(() => {
    const navItemsIds = navItems.map(({ section_id }) => section_id);
    if (!isNavigating) {
      const mainElements =
        sections &&
        sections.map(({ section_details, sectionDetails }) => {
          const sectionId =
            section_details?.section_id ||
            sectionDetails?.section_id ||
            ("" as string);
          return document.getElementById(sectionId);
        });

      const observer = new IntersectionObserver(
        (entries) => {
          for (let entry of entries) {
            if (entry.isIntersecting && !isNavigating) {
              const id = entry.target.id;
              trackEvent(`VIEW_${id}`);
              if (navItemsIds.includes(id)) {
                setActiveMenu(entry.target.id);
              }
            }
          }
        },
        {
          threshold: 0.5,
        }
      );

      mainElements &&
        mainElements.forEach((element) => element && observer.observe(element));

      // cleanup function
      return () => {
        mainElements &&
          mainElements.forEach(
            (element) => element && observer.unobserve(element)
          );
      };
    }
  }, [isNavigating, navItems, sections]);

  const getSection = (section: any) => {
    const { section_details, sectionDetails } = section;
    const sectionId =
      section_details?.section_id || sectionDetails?.section_id || "";
    switch (sectionId) {
      case STRAPI_SECTION_IDS.MEET: {
        return (
          <Meet
            key="meet"
            sectionDetails={section_details}
            meetData={section?.about_data || []}
          />
        );
      }
      case STRAPI_SECTION_IDS.HOWITWORKS: {
        return (
          <How
            key="how"
            sectionDetails={section_details}
            imgKey={section?.img_key}
            link={section?.iframe_link_url}
            worksData={section?.data}
          />
        );
      }
      case STRAPI_SECTION_IDS.REWARDSCALCULATOR: {
        return (
          <RewardCalculator
            key="rewardCalculator"
            sectionDetails={section_details}
          />
        );
      }
      case STRAPI_SECTION_IDS.AUDITS: {
        return (
          <Audits
            key="audits"
            sectionDetails={section_details || sectionDetails}
            imgKey={section?.img_key || ""}
          />
        );
      }
      case STRAPI_SECTION_IDS.PARTNERS: {
        return (
          <Partners
            key="partners"
            sectionDetails={section_details || sectionDetails}
            partnersData={section?.data}
          />
        );
      }
      case STRAPI_SECTION_IDS.BLOGS: {
        return <Blogs key="blogs" sectionDetails={section_details} />;
      }
      case STRAPI_SECTION_IDS.SUBSCRIBE: {
        return (
          <Newsletter
            key="newsletter"
            sectionDetails={section_details}
            subscribeForm={section?.subscribe_form}
          />
        );
      }
      case STRAPI_SECTION_IDS.KNOWMORE: {
        return <KnowMore key="knowmore" sectionDetails={section_details} />;
      }
      case STRAPI_SECTION_IDS.NODEBANNER: {
        return (
          <NodeOperatorsBanner
            key="nodeOperatorBanner"
            sectionDetails={section_details}
            img_key={section.bg_img_key}
          />
        );
      }
      case STRAPI_SECTION_IDS.FAQS: {
        return (
          <FAQs
            key="faqs"
            sectionDetails={section_details}
            faqs={section?.faqs}
          />
        );
      }
      case STRAPI_SECTION_IDS.FAQSCONTENT: {
        return (
          <FAQsContent
            key="faqContent"
            sectionDetails={section_details}
            faqs={section?.faqs}
          />
        );
      }
    }
  };

  return (
    <>
      <LightMode>
        <div data-theme="light">
          <Center bg="#FFFFFF" flexDir="column" w="100vw" color="textPrimary">
            <Header />
            <Hero
              sectionDetails={section_details}
              communities={communities}
              actionItems={pageData.banner_cta_section}
              statistics={pageData.statistics}
            />

            <Center
              w="100%"
              flexDir="column"
              position="sticky"
              top={{ base: "62px", lg: "72px" }}
              bg={isSubHeaderSticky ? "white" : "white"}
              zIndex={10}
              mt={{ base: "40px", lg: 0 }}
              pt="24px"
              id="sub-header"
            >
              <Flex
                w={{ base: "100%", xl: "1196px" }}
                gap="1rem"
                overflowX="auto"
                className="scroll-container"
                justifyContent={{ base: "flex-start", md: "center" }}
              >
                {navItems.map(({ section_id, section_title }) => (
                  <Center
                    key={section_id}
                    id={`${section_id}-tab`}
                    cursor="pointer"
                    flexDir="column"
                    p="0"
                    px="1rem"
                    minW="fit-content"
                    onClick={() => {
                      trackEvent(PRIMARY_TABS, { tab_name: section_id });
                      setNavigating(true);
                      setActiveMenu(section_id);
                    }}
                    fontSize={{ base: 12, lg: 16 }}
                  >
                    <Text
                      color={activeMenu === section_id ? "#142528" : "#3F6870"}
                      mb="1rem"
                    >
                      {section_title}
                    </Text>
                    {activeMenu === section_id ? (
                      <Divider
                        w={{ base: "40px", lg: "80px" }}
                        border="2px solid"
                        borderColor="black"
                        borderTopRadius={8}
                      />
                    ) : null}
                  </Center>
                ))}
              </Flex>
              <Divider border="1px solid" borderColor="#AEDFCA" w="100vw" />
            </Center>

            {sections && sections.map((section: any) => getSection(section))}
          </Center>
          <Footer data={navData} />
        </div>
      </LightMode>
    </>
  );
};

const HomePage = (props: { page: any }) => {
  return (
    <PageHead page="index">
      <PageProvider page={props.page}>
        <Home />
      </PageProvider>
    </PageHead>
  );
};

export async function getStaticProps() {
  const [resJson, navigationData] = await Promise.all([
    fetchPageData(
      "chain-home-page-v2s",
      undefined,
      `filters[key][$eq]=${process.env.NEXT_PUBLIC_TOKEN?.toLowerCase() || ""}`
    ),
    fetchPageData("landing-navigation"),
  ]);
  const pageData = (resJson.data && resJson?.data[0]?.attributes) || null;
  const pageError = pageData?.error || null;

  const navData = navigationData.data?.attributes || null;
  const navError = navigationData.error || null;
  return {
    props: {
      page: {
        data: {
          navData: navData,
          pageData: pageData,
        },
        error: { navError, pageError },
      },
    },
  };
}

HomePage.isHomePage = true;
export default HomePage;
